import React, { useEffect, useState } from 'react';
import { Form, Select, Divider, Popconfirm, Button, Switch, message, Table } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { EventLayout } from '@components/Layout';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';

import useAuth from '@hooks/auth/useAuth';
import { useRoles } from '@hooks/useRoles';
import { useLanguage } from '@hooks/useTranslations';
import { useVideoCalls } from '@hooks/useVideoCalls';
import { useWipeChat, useExportChat } from '@hooks/useChat';
import { usePages, usePushToPage, usePushSlido } from '@hooks/usePages';
import { useCurrentEvent, useUpdateEvent, useEventTemplate } from '@hooks/useEvents';

import { Event } from '@transforms/event';
import type { Page } from '@transforms/page';

export default function ControlPanel() {
  const [form] = Form.useForm();
  const { selectedLanguage } = useLanguage();
  const { data: event } = useCurrentEvent(selectedLanguage);
  const { mutate: updateEvent } = useUpdateEvent(event?.id);
  const { data: pages } = usePages();
  const { data: videoCalls } = useVideoCalls(event?.id);
  const { pushToPage } = usePushToPage(event?.id);
  const { pushSlido } = usePushSlido(event?.id);
  const { mutate: wipeChat } = useWipeChat(event?.id);
  const [initialPages, setInitialPages] = useState<Array<{ startDate: Date; pageId?: string }>>([]);
  const { exportChat } = useExportChat(event?.id);
  const { data: roles } = useRoles(selectedLanguage, event?.id);
  const { isEvent } = useEventTemplate();
  const { user, isSuperAdmin } = useAuth();
  const isOrganisationAdmin = user?.organisations?.some(({ id }) => id === event?.organisationId);

  useEffect(
    () =>
      setInitialPages((current) => {
        if (current.length === 0 && event?.initialPages) {
          return event.initialPages;
        }

        return current;
      }),
    [event],
  );

  const onUpdateEvent = (values: Event) => {
    updateEvent(
      { ...event, ...values, initialPages },
      {
        onSuccess() {
          message.success({
            content: (
              <div className='message-box-content-container'>
                <div className='message-box-content'>
                  <h3>Event Updated!</h3>
                  <span>Your changes have been successfully updated and saved!</span>
                </div>
              </div>
            ),
            className: 'message-box',
          });
        },
        onError() {
          message.error({
            content: (
              <div className='message-box-content-container'>
                <div className='message-box-content'>
                  <h3>Update Failed!</h3>
                  <span>An error occured while saving your changes. Please try again.</span>
                </div>
              </div>
            ),
            className: 'message-box',
          });
        },
      },
    );
  };

  const onPushToPage = async ({ pageId, userRoles }: { pageId: string; userRoles?: string[] }) => {
    const response = await pushToPage(pageId, userRoles);

    if (response.status >= 200 && response.status < 300) {
      message.success({
        content: (
          <div className='message-box-content-container'>
            <div className='message-box-content'>
              <h3>Attendees Pushed!</h3>
              <span>Attendees have been succesfully pushed to the selected destination!</span>
            </div>
          </div>
        ),
        className: 'message-box',
      });
      form.resetFields();
    } else {
      message.error('An error occurred');
    }
  };

  const onPushSlido = async ({ pageId }: { pageId: string }) => {
    const response = await pushSlido(pageId);

    if (response.status === 201) {
      message.success({
        content: (
          <div className='message-box-content-container'>
            <div className='message-box-content'>
              <h3>Slido Pushed!</h3>
              <span>Slido been succesfully pushed to open at the selected page!</span>
            </div>
          </div>
        ),
        className: 'message-box',
      });
      form.resetFields();
    } else {
      message.error('An error occurred');
    }
  };

  const renderPushToPage = (
    <>
      <h1>Push Attendees</h1>
      <p>
        Immediately send all attendees (or selected user roles) to a specific platform page or video
        call.
      </p>
      <Form
        form={form}
        layout='vertical'
        onFinish={onPushToPage}
        validateMessages={{
          required: 'This is a required field.',
        }}
      >
        <Form.Item
          name='pageId'
          rules={[{ required: true }]}
          label={<span style={{ fontWeight: 'bold' }}>Destination</span>}
        >
          <Select placeholder='Select a Platform Page or Video Call.'>
            {[...(pages ?? []), ...(videoCalls ?? [])].map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.title} {(item as Page).slug ? '' : '- Video call'}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginBottom: '24px',
          }}
        >
          <Form.Item
            name='userRoles'
            rules={[{ required: true }]}
            style={{ flex: 1, marginBottom: 0, marginRight: '10px' }}
            label={<span style={{ fontWeight: 'bold' }}>User Roles</span>}
          >
            <Select
              mode='multiple'
              placeholder='Push attendees with only these specific user roles.'
              options={
                roles?.map((role) => ({
                  value: role.id,
                  label: role.name,
                })) ?? []
              }
            />
          </Form.Item>
          <div style={{ marginTop: '30px' }}>
            <Button
              danger
              style={{ marginRight: '10px' }}
              onClick={() => form.setFieldsValue({ userRoles: [] })}
            >
              Clear
            </Button>
            <Button
              type='primary'
              onClick={() => form.setFieldsValue({ userRoles: roles?.map((role) => role.id) })}
            >
              Add All
            </Button>
          </div>
        </div>
        <Button htmlType='submit' type='primary'>
          Push Attendees
        </Button>
      </Form>
    </>
  );

  const renderChatOptions = (
    <>
      <Divider key='d1' />
      <h1>Chat Settings</h1>

      <Form.Item
        name='chatEnabled'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Group Chat</div>
            <div>
              By enabling this feature, attendees can view and participate in a public chat.
            </div>
          </div>
        }
      >
        {event && (
          <Switch
            disabled={event?.organisation?.chatEnabled === false}
            defaultChecked={event?.organisation?.chatEnabled !== false ? event.chatEnabled : false}
          />
        )}
      </Form.Item>

      <Form.Item
        name='hostChatEnabled'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Host Chat</div>
            <div>
              By enabling this feature, attendees can send direct messages to the event host(s).
            </div>
          </div>
        }
      >
        {event && (
          <Switch
            disabled={event?.organisation?.hostChatEnabled === false}
            defaultChecked={
              event?.organisation?.hostChatEnabled !== false ? event.hostChatEnabled : false
            }
          />
        )}
      </Form.Item>

      <Form.Item
        name='privateChatEnabled'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Private Chat</div>
            <div>
              By enabling this feature, attendees can send direct messages to each other.
              <br />
              <b>Note:</b> these chats are currently limited to 1-on-1 conversations; there are no
              private group chats at this time.
            </div>
          </div>
        }
      >
        {event && (
          <Switch
            disabled={event?.organisation?.privateChatEnabled === false}
            defaultChecked={
              event?.organisation?.privateChatEnabled !== false ? event.privateChatEnabled : false
            }
          />
        )}
      </Form.Item>

      {(isSuperAdmin || isOrganisationAdmin) && (
        <Form.Item
          name='videoCallEnabled'
          valuePropName='checked'
          label={
            <div>
              <div style={{ fontWeight: 'bold' }}>Private Video Call</div>
              <div>
                By enabling this feature, attendees are allowed to video call (1-on-1) other
                attendees.
                <br />
                <b>Note:</b> Only attendees with the user role permission setting{' '}
                <b>Video Call Host</b> can create and control group video call(s).
              </div>
            </div>
          }
        >
          {event && (
            <Switch
              disabled={event?.organisation?.videoCallsEnabled === false}
              defaultChecked={
                event?.organisation?.videoCallsEnabled !== false ? event.videoCallEnabled : false
              }
            />
          )}
        </Form.Item>
      )}

      <Form.Item
        name='export-chat'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Export Chat</div>
            <div>Download an export (CSV file) of all the messages within the chat.</div>
          </div>
        }
      >
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          onClick={() => {
            exportChat();
          }}
        >
          Export Chat
        </Button>
      </Form.Item>

      <Form.Item
        name='wipe-group-chat'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Delete Chat</div>
            <div>
              Delete the entire chat and all data associated with it! Note, this cannot be undone.
            </div>
          </div>
        }
      >
        <Popconfirm
          title={
            <div className='message-box-content-container'>
              <div className='message-box-content'>
                <h3>Confirm Deletion!</h3>
                <span>
                  Are you sure you want to delete all messages and any associated data within the
                  group chat? Note, this cannot be undone!
                </span>
              </div>
            </div>
          }
          onConfirm={() => {
            wipeChat();
            message.info('Chat wiped');
          }}
          okText='DELETE'
          cancelText='Cancel'
          okButtonProps={{
            danger: true,
          }}
        >
          <Button type='primary' danger icon={<DeleteOutlined />}>
            Delete Chat
          </Button>
        </Popconfirm>
      </Form.Item>
    </>
  );

  const renderAttendeeSettings = (
    <>
      <Divider key='d2' />
      <h1>Attendee Settings</h1>

      <Form.Item
        name='singleSessionPerMagicLink'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Restricted User Login</div>
            <div>
              By enabling this feature, attendees can only be logged in (with their personal link)
              on one device simultaneously.
            </div>
          </div>
        }
      >
        {event && <Switch defaultChecked={!!event.singleSessionPerMagicLink} />}
      </Form.Item>

      <Form.Item
        name='avatarUploadEnabled'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Profile Picture</div>
            <div>
              By enabling this feature, attendees can upload a profile photo (during registration,
              onboarding or profile edit).
            </div>
          </div>
        }
      >
        {event && <Switch defaultChecked={event.avatarUploadEnabled} />}
      </Form.Item>

      <Form.Item
        name='rolesEnabled'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>User Role Name (Tag) Visibility</div>
            <div>
              By enabling this feature, attendees can see their assigned roles (as tags).
              <br />
              Note, you can toggle visiblity for each role from{' '}
              <Link to={`/events/${event?.id}/roles`}>User Roles</Link>.
            </div>
          </div>
        }
      >
        {event && <Switch defaultChecked={event.rolesEnabled} />}
      </Form.Item>

      <Form.Item
        name='attendeesInSidebarEnabled'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Attendee Shortcut Icon</div>
            <div>
              By enabling this feature, attendees can see the Attendee Shortcut Icon in the sidebar.
              <br />
              Note, you can toggle this visiblity for each role from{' '}
              <Link to={`/events/${event?.id}/roles`}>User Roles</Link>; however, the endpoint for
              retrieving all attendees will always be available regardless of this setting.
            </div>
          </div>
        }
      >
        {event && <Switch defaultChecked={event.attendeesInSidebarEnabled} />}
      </Form.Item>
    </>
  );

  const renderSlidoPushSettings = (
    <>
      <Divider key='d2' />
      <h1>Push Slido Sidebar</h1>

      <Table
        dataSource={pages?.filter((page) => page.template === 'LIVE')}
        columns={[
          {
            title: 'Page',
            dataIndex: 'title',
            key: 'title',
          },
          {
            title: 'Open Slido',
            dataIndex: 'onPushSlido',
            key: 'pushSlido',
            render: (_, page) => {
              return (
                <Button
                  type='primary'
                  onClick={() => {
                    onPushSlido({ pageId: page.id });
                  }}
                >
                  Open Slido
                </Button>
              );
            },
          },
        ]}
        pagination={false}
      />

      <Button htmlType='submit' type='primary' style={{ float: 'right' }}>
        Save
      </Button>
    </>
  );

  const renderEventOptions = (
    <>
      <Form
        layout='vertical'
        onFinish={onUpdateEvent}
        initialValues={{
          ...event,
          chatEnabled: event?.organisation?.chatEnabled !== false ? event?.chatEnabled : false,
          privateChatEnabled:
            event?.organisation?.privateChatEnabled !== false ? event?.privateChatEnabled : false,
          hostChatEnabled:
            event?.organisation?.hostChatEnabled !== false ? event?.hostChatEnabled : false,
          videoCallEnabled:
            event?.organisation?.videoCallsEnabled !== false ? event?.videoCallEnabled : false,
        }}
      >
        {isEvent && renderChatOptions}
        {renderAttendeeSettings}
        {renderSlidoPushSettings}
      </Form>
    </>
  );

  return (
    <EventLayout title='Control Panel' extra={<EventLanguageSelect key='language-select' />}>
      {isEvent && renderPushToPage}
      {event && renderEventOptions}
    </EventLayout>
  );
}
