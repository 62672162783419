import React from 'react';
import { Divider, Form, Input, Switch } from 'antd';

import { Link } from 'react-router-dom';

import useAuth from '@hooks/auth/useAuth';
import { useCurrentEvent } from '@hooks/useEvents';

export function VirtualVenue() {
  const { isSuperAdmin, organisation } = useAuth();

  const { data: event } = useCurrentEvent();
  return (
    <>
      <h2>Virtual Venue Settings</h2>
      {(isSuperAdmin || organisation) && (
        <Form.Item
          name={['parameters', 'venueUrl']}
          label={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontWeight: 'bold' }}>Venue URL</div>
              <div>
                Friendly reminder to replace the original prefix "
                <a>s3.eu-central-1.amazonaws.com/venue</a>" with "<a>venuecf</a>" once venue has
                been finalized. This is so the Venue CloudFront URL will cache to a server that's
                faster and nearest to the user. The original (raw) link points to a server in Europe
                which makes the load speed noticeably slower for any international users. Since it
                is being distributed over different servers, this altered link will also allow for
                more simultaneous load requests and thus, a more stable user experience.
              </div>
            </div>
          }
        >
          <Input placeholder='The URL (web address) to embed.' />
        </Form.Item>
      )}
      <Divider key='d1' />
      <h3>Group Chat</h3>
      <p>
        Toggle on to activate or off to deactivate group chat on this page. Note, you can enable or
        disable group chat entirely from the{' '}
        <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link>.
      </p>

      <Form.Item
        name='groupChatEnabled'
        style={{ fontWeight: 'bold' }}
        label='Enabled'
        valuePropName='checked'
      >
        <Switch defaultChecked />
      </Form.Item>
      <Form.Item
        name={['parameters', 'chatEnabled']}
        style={{ fontWeight: 'bold' }}
        label='Expanded by Default'
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
      <Divider key='d2' />
    </>
  );
}
