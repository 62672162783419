import React from 'react';
import { PageHeader, Layout, Form, Input, InputNumber, Switch, Button, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useProtected } from '@hooks/useProtected';
import { useCreateOrganisation } from '@hooks/useOrganisations';
import { PageTemplate } from '@transforms/page';
import { Organisation } from '@transforms/organisation';

export default function NewOrganisation() {
  useProtected({ checkSuperAdmin: true });
  const { mutate: createOrganisation } = useCreateOrganisation();
  const navigate = useNavigate();

  const onCreateOrganisation = (values: Organisation) => {
    createOrganisation(values, {
      onSuccess: () => {
        navigate('/organisations');
      },
    });
  };

  return (
    <Layout.Content className='site-layout-content'>
      <PageHeader title='New organization' />
      <Form
        {...{
          labelCol: { span: 3 },
          wrapperCol: { span: 9 },
          initialValues: {
            enabledPageTemplates: [
              PageTemplate.ATTENDEES,
              PageTemplate.SCHEDULE,
              PageTemplate.IFRAME,
              PageTemplate.LIVE,
              PageTemplate.VIDEO_ON_DEMAND,
              PageTemplate.VIRTUAL_VENUE,
              PageTemplate.BASIC,
              PageTemplate.BREAKOUT,
              PageTemplate.CANVAS,
            ],
          },
        }}
        onFinish={onCreateOrganisation}
      >
        <Form.Item name='name' label='Name'>
          <Input placeholder='Name' />
        </Form.Item>
        <Form.Item name='internalName' label='Internal name'>
          <Input placeholder='Internal name' />
        </Form.Item>
        <Form.Item name='contactName' label='Contact name'>
          <Input placeholder='Contact name' />
        </Form.Item>
        <Form.Item name='contactEmail' label='Contact email' rules={[{ type: 'email' }]}>
          <Input placeholder='Contact email' />
        </Form.Item>
        <Form.Item name='salesforceNumber' label='Salesforce number'>
          <Input placeholder='Salesforce number' />
        </Form.Item>
        <Form.Item name='companyDetails' label='Company details'>
          <Input placeholder='Company details' />
        </Form.Item>
        <Form.Item name='subscriptionDetails' label='Subscription details'>
          <Input placeholder='Subscription details' />
        </Form.Item>
        <Form.Item name='eventLimit' label='Event limit' rules={[{ type: 'number', min: 1 }]}>
          <InputNumber placeholder='Event limit' min={1} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='activeEventLimit'
          label='Active event limit'
          rules={[{ type: 'number', min: 1 }]}
        >
          <InputNumber placeholder='Active event limit' min={1} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='userLimit'
          label='Attendee limit per event'
          rules={[{ type: 'number', min: 1 }]}
        >
          <InputNumber placeholder='Attendee limit per event' min={1} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name='enabledPageTemplates' label='Page types enabled'>
          <Select placeholder='What page templates should be enabled' mode='multiple' allowClear>
            <Select.Option value={PageTemplate.ATTENDEES}>Attendees</Select.Option>
            <Select.Option value={PageTemplate.SCHEDULE}>Schedule</Select.Option>
            <Select.Option value={PageTemplate.IFRAME}>iFrame</Select.Option>
            <Select.Option value={PageTemplate.LIVE}>Live</Select.Option>
            <Select.Option value={PageTemplate.VIDEO_ON_DEMAND}>Video On Demand</Select.Option>
            <Select.Option value={PageTemplate.VIRTUAL_VENUE}>Virtual Venue</Select.Option>
            <Select.Option value={PageTemplate.BASIC}>Basic Content Page</Select.Option>
            <Select.Option value={PageTemplate.BREAKOUT}>Break Out Page</Select.Option>
            <Select.Option value={PageTemplate.CANVAS}>Canvas</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name='chatEnabled' valuePropName='checked' label='Chat enabled'>
          <Switch defaultChecked />
        </Form.Item>
        <Form.Item name='privateChatEnabled' valuePropName='checked' label='Private chat enabled'>
          <Switch defaultChecked />
        </Form.Item>
        <Form.Item name='hostChatEnabled' valuePropName='checked' label='Host chat enabled'>
          <Switch defaultChecked />
        </Form.Item>
        <Form.Item name='videoCallsEnabled' valuePropName='checked' label='Video calls enabled'>
          <Switch defaultChecked />
        </Form.Item>
        <Form.Item name='registrationEnabled' valuePropName='checked' label='Registration enabled'>
          <Switch defaultChecked />
        </Form.Item>
        <Form.Item
          name='eventTemplatesEnabled'
          valuePropName='checked'
          label='Event templates enabled'
        >
          <Switch defaultChecked />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Create organization
          </Button>
        </Form.Item>
      </Form>
    </Layout.Content>
  );
}
