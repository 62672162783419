import { Divider, Form, Input, Select, Switch } from 'antd';

import { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { VideoTypes } from '@components/Modals';

import { VideoType } from '@transforms/video';

import { useCurrentEvent } from '@hooks/useEvents';

import type { PageProps } from './props';

export function Live({ page }: PageProps) {
  const [videoType, setVideoType] = useState(page.parameters?.videoType);
  const [slidoEnabled, setSlidoEnabled] = useState(false);
  const slidoSwitch = useRef<HTMLElement>(null);
  const { data: event } = useCurrentEvent();

  useEffect(() => {
    const checked = slidoSwitch.current?.getAttribute('aria-checked');
    setSlidoEnabled(checked === 'true');
  }, [slidoEnabled, slidoSwitch]);

  useEffect(() => {
    if (page?.parameters && !videoType) setVideoType(page.parameters?.videoType || VideoType.VIXY);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- no re-run on videoType change
  }, [page, setVideoType]);

  const renderVideoTypeFields = () => {
    if (videoType === undefined) {
      return null;
    }

    switch (videoType) {
      case VideoType.VIXY:
        return (
          <>
            <Form.Item
              name={['parameters', 'vixyPlayerId']}
              label={<b>VIXY UICONF ID</b>}
              rules={[{ required: true }]}
            >
              <Input placeholder='VixyVideo player ID' />
            </Form.Item>
            <Form.Item
              name={['parameters', 'vixyEntryId']}
              label={<b>VIXY Entry ID</b>}
              rules={[{ required: true }]}
            >
              <Input placeholder='VixyVideo entry ID' />
            </Form.Item>
            <Form.Item
              name={['parameters', 'vixyPartnerId']}
              label={<b>VIXY Partner ID</b>}
              rules={[{ required: true }]}
            >
              <Input placeholder='VixyVideo partner ID' />
            </Form.Item>
          </>
        );
      case VideoType.VIMEO:
        return (
          <>
            <Form.Item
              name={['parameters', 'vimeoVideoId']}
              label={<b>Vimeo Video ID</b>}
              rules={[{ required: true }]}
            >
              <Input placeholder='Vimeo video ID' />
            </Form.Item>
          </>
        );
      case VideoType.HLS:
        return (
          <>
            <Form.Item
              name={['parameters', 'hlsUrl']}
              label={<b>HLS URL</b>}
              rules={[{ required: true }]}
            >
              <Input placeholder='Paste your HLS URL here.' />
            </Form.Item>
          </>
        );
      case VideoType.OTHER:
        return (
          <>
            <Form.Item
              name={['parameters', 'embedUrl']}
              label={<b>Livestream URL</b>}
              rules={[{ required: true }]}
            >
              <Input placeholder='Paste your custom embed URL here. video ID' />
            </Form.Item>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <h2>Live Settings</h2>
      <h3>Livestream</h3>
      <Form.Item name={['parameters', 'description']} label={<b>Livestream Description</b>}>
        <Input placeholder='A short description for this livestream.' />
      </Form.Item>
      <Form.Item name={['parameters', 'videoType']} label={<b>Video Provider</b>}>
        <Select
          defaultActiveFirstOption
          defaultValue={videoType}
          options={VideoTypes}
          onChange={(value) => setVideoType(value)}
        />
      </Form.Item>
      {renderVideoTypeFields()}
      <Divider key='d1' />
      <h3>Integrations</h3>
      <Form.Item
        name={['parameters', 'virtualAudienceDescription']}
        label='Virtual Audience Description'
        style={{ fontWeight: 'bold' }}
      >
        <Input.TextArea placeholder='A short description for this virtual audience.' />
      </Form.Item>
      <Form.Item
        name={['parameters', 'virtualAudienceURL']}
        style={{ fontWeight: 'bold' }}
        label='Virtual Audience URL'
      >
        <Input placeholder='The URL (web address) to embed.' />
      </Form.Item>
      <Form.Item
        name={['parameters', 'virtualAudienceButton']}
        label='Virtual Audience Button'
        style={{ fontWeight: 'bold' }}
      >
        <Input placeholder='A short text for the button to join this virtual audience.' />
      </Form.Item>
      <Form.Item
        name={['parameters', 'slidoUrl']}
        style={{ fontWeight: 'bold' }}
        label='Slido URL'
        rules={[{ required: slidoEnabled, message: 'This is a required field.' }]}
      >
        <Input placeholder='The URL (web address) to embed.' />
      </Form.Item>
      <Form.Item
        name={['parameters', 'slidoEnabled']}
        style={{ fontWeight: 'bold' }}
        label='Expanded by Default'
        valuePropName='checked'
      >
        <Switch ref={slidoSwitch} onChange={(e) => setSlidoEnabled(e)} />
      </Form.Item>
      <Divider key='d2' />
      <h3>Group Chat</h3>
      <p>
        Toggle on to activate or off to deactivate group chat on this page. Note, you can enable or
        disable group chat entirely from the{' '}
        <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link>.
      </p>

      <Form.Item
        name='groupChatEnabled'
        style={{ fontWeight: 'bold' }}
        label='Enabled'
        valuePropName='checked'
      >
        <Switch defaultChecked />
      </Form.Item>
      <Form.Item
        name={['parameters', 'chatEnabled']}
        style={{ fontWeight: 'bold' }}
        label='Expanded by Default'
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
      <Divider key='d3' />
      <h2>Live Reactions</h2>
      <h3>
        Applause{' '}
        <span role='img' aria-label='clap'>
          👏
        </span>
      </h3>
      <p>Toggle on to activate or off to deactivate audience applause.</p>
      <Form.Item
        name='clapHandsEnabled'
        style={{ fontWeight: 'bold' }}
        label='Enabled'
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
      <Divider key='d4' />
    </>
  );
}
