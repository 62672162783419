import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Alert,
  Input,
  Button,
  message,
  Table,
  Popconfirm,
  Radio,
  Divider,
  Tag,
  Tooltip,
} from 'antd';
import { ChromePicker } from 'react-color';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';

import { useLanguage } from '@hooks/useTranslations';
import { useCurrentEvent } from '@hooks/useEvents';
import {
  useRoles,
  useUpdateRolesStart,
  useUpdateRolesFinish,
  useUpdateAttendeeRoles,
  useAttendeeRoles,
  usePageRoles,
  useUpdatePageRoles,
  useUpdateSessionRoles,
  useSessionRoles,
  useUpdateBreakoutRoles,
  useBreakoutRoles,
} from '@hooks/useRoles';
import { UserRolePermission, UserRoleResource } from '@transforms/userRole';
import { EventLayout } from '@components/Layout';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';
import { LazyModal } from '@components/LazyModal';
import {
  AttendeeRole,
  BreakoutModal,
  BreakoutRole,
  DeleteWarningModal,
  EditRolePermissionsModal,
  PageModal,
  PageRole,
  SessionModal,
  SessionRole,
  UserModal,
} from '@components/Roles';

function isInViewport(element: HTMLElement | null) {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  return rect.top > -55; // 55 is the height of the table header in px
}

function useComponentVisible() {
  const [isComponentVisible, setIsComponentVisible] = useState<{
    id: string | null;
    node: HTMLDivElement | null;
  }>({ id: null, node: null });
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentVisible({ id: null, node: null });
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside as any, true);
    return () => {
      document.removeEventListener('click', handleClickOutside as any, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

function throwUpdateError(title: string, extra?: string) {
  message.error({
    content: (
      <div className='message-box-content-container'>
        <div className='message-box-content'>
          <h3>{title} Update Failed!</h3>
          <span>
            An error occured while saving your changes. We were unable to assign one or more{' '}
            {title.toLowerCase()} {extra}their new roles, please try again after refreshing the
            page.
          </span>
        </div>
      </div>
    ),
    className: 'message-box',
  });
}

export default function Roles() {
  const { data: event } = useCurrentEvent();
  const { selectedLanguage } = useLanguage();
  const { mutateAsync: updateRolesStart } = useUpdateRolesStart(event?.id, selectedLanguage);
  const { mutate: updateRolesFinish } = useUpdateRolesFinish(event?.id, selectedLanguage);
  const { data: roles, isLoading, isFetching } = useRoles(selectedLanguage, event?.id);

  const columns = useRef<HTMLDivElement | null>(null);
  const [showHeaderTooltip, setShowHeaderTooltip] = useState(false);
  const { ref: colorPicker, isComponentVisible, setIsComponentVisible } = useComponentVisible();

  const [showAttendeeModal, setShowAttendeeModal] = useState<UserRoleResource | null>(null);
  const { data: attendeeRoles } = useAttendeeRoles(event?.id, selectedLanguage);
  const [updatedAttendeeRoles, setUpdatedAttendeeRoles] = useState(attendeeRoles);
  const { mutateAsync: updateAttendeeRoles } = useUpdateAttendeeRoles(event?.id);

  const [showPageModal, setShowPageModal] = useState<UserRoleResource | null>(null);
  const { data: pageRoles } = usePageRoles(event?.id, selectedLanguage);
  const [updatedPageRoles, setUpdatedPageRoles] = useState(pageRoles);
  const { mutateAsync: updatePageRoles } = useUpdatePageRoles(event?.id);

  const [showSessionModal, setShowSessionModal] = useState<UserRoleResource | null>(null);
  const { data: sessionRoles } = useSessionRoles(event?.id, selectedLanguage);
  const [updatedSessionRoles, setUpdatedSessionRoles] = useState(sessionRoles);
  const { mutateAsync: updateSessionRoles } = useUpdateSessionRoles(event?.id);

  const [showBreakoutModal, setShowBreakoutModal] = useState<UserRoleResource | null>(null);
  const { data: breakoutRoles } = useBreakoutRoles(event?.id, selectedLanguage);
  const [updatedBreakoutRoles, setUpdatedBreakoutRoles] = useState(breakoutRoles);
  const { mutateAsync: updateBreakoutRoles } = useUpdateBreakoutRoles(event?.id);

  const visibilityDisabled = event?.rolesEnabled === false;
  const attendeesSidebarDisabled = event?.attendeesInSidebarEnabled === false;

  const [rolePermissionsModal, setRolePermissionsModal] = useState<string | null>(null);
  const [updatedRoles, setUpdatedRoles] = useState(roles ?? []);
  const [showDeleteModal, setShowDeleteModal] = useState<string | null>(null);

  useEffect(() => {
    if (!updatedAttendeeRoles) setUpdatedAttendeeRoles(attendeeRoles);
  }, [updatedAttendeeRoles, attendeeRoles]);

  useEffect(() => {
    if (!updatedPageRoles) setUpdatedPageRoles(pageRoles);
  }, [updatedPageRoles, pageRoles]);

  useEffect(() => {
    if (!updatedSessionRoles) setUpdatedSessionRoles(sessionRoles);
  }, [updatedSessionRoles, sessionRoles]);

  useEffect(() => {
    if (!updatedBreakoutRoles) setUpdatedBreakoutRoles(breakoutRoles);
  }, [updatedBreakoutRoles, breakoutRoles]);

  useEffect(() => {
    setUpdatedRoles((prev) => roles ?? prev);
  }, [roles]);

  useEffect(() => {
    const listener = () => setShowHeaderTooltip(!isInViewport(columns.current));

    listener();
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, [showHeaderTooltip]);

  const onUpdateRoles = useCallback(async () => {
    const newRoles = await updateRolesStart(updatedRoles);

    try {
      await updateAttendeeRoles(
        // replace "new-..." ids with the new ids from the backend
        updatedAttendeeRoles?.map((updated) =>
          updated.roleId?.startsWith('new-')
            ? {
                ...updated,
                roleId: newRoles.find((role) => role.tempId === updated.roleId)!.id,
              }
            : updated,
        ) ?? [],
      );
    } catch {
      throwUpdateError('Attendees', 'to ');
    }

    try {
      await updatePageRoles(
        // replace "new-..." ids with the new ids from the backend
        updatedPageRoles?.map((updated) =>
          updated.roleIds.some((roleId) => roleId.startsWith('new-'))
            ? {
                ...updated,
                roleIds: updated.roleIds.map(
                  (roleId) => newRoles.find((role) => role.tempId === roleId)!.id,
                ),
              }
            : updated,
        ) ?? [],
      );
    } catch {
      throwUpdateError('Pages');
    }

    try {
      await updateSessionRoles(
        // replace "new-..." ids with the new ids from the backend
        updatedSessionRoles?.map((updated) =>
          updated.roleIds.some((roleId) => roleId.startsWith('new-'))
            ? {
                ...updated,
                roleIds: updated.roleIds.map(
                  (roleId) => newRoles.find((role) => role.tempId === roleId)!.id,
                ),
              }
            : updated,
        ) ?? [],
      );
    } catch {
      throwUpdateError('Sessions');
    }

    try {
      await updateBreakoutRoles(
        // replace "new-..." ids with the new ids from the backend
        updatedBreakoutRoles?.map((updated) =>
          updated.roleIds.some((roleId) => roleId.startsWith('new-'))
            ? {
                ...updated,
                roleIds: updated.roleIds.map(
                  (roleId) => newRoles.find((role) => role.tempId === roleId)!.id,
                ),
              }
            : updated,
        ) ?? [],
      );
    } catch {
      throwUpdateError('Breakouts');
    }

    const updatedRolesWithIds = updatedRoles.map((role) => ({
      ...role,
      id: role.id.startsWith('new-')
        ? newRoles.find(({ tempId }) => tempId === role.id)?.id ?? role.id
        : role.id,
    }));

    updateRolesFinish(updatedRolesWithIds, {
      onSuccess: () => {
        message.success({
          content: (
            <div className='message-box-content-container'>
              <div className='message-box-content'>
                <h3>Roles Updated!</h3>
                <span>Your changes have been successfully updated and saved!</span>
              </div>
            </div>
          ),
          className: 'message-box',
        });
      },
      onError: (error: any) => {
        if (error?.response?.body?.message === 'vle_error_user_role_in_use') {
          message.error({
            content: (
              <div className='message-box-content-container'>
                <div className='message-box-content'>
                  <h3>Update Failed!</h3>
                  <span>
                    An error occured while saving your changes. One or more deleted roles are still
                    in use by attendees, please change their role.
                  </span>
                </div>
              </div>
            ),
            className: 'message-box',
          });
        } else {
          message.error({
            content: (
              <div className='message-box-content-container'>
                <div className='message-box-content'>
                  <h3>Update Failed!</h3>
                  <span>An error occured while saving your changes. Please try again.</span>
                </div>
              </div>
            ),
            className: 'message-box',
          });
        }
      },
    });
  }, [
    updatedRoles,
    updatedAttendeeRoles,
    updatedPageRoles,
    updatedSessionRoles,
    updatedBreakoutRoles,
    updateRolesStart,
    updateRolesFinish,
    updateAttendeeRoles,
    updatePageRoles,
    updateSessionRoles,
    updateBreakoutRoles,
  ]);

  const onEditUsers = useCallback(async (newRoles: AttendeeRole[]) => {
    setUpdatedAttendeeRoles((prev) => [
      ...(prev ?? []).filter(({ id }) => !newRoles.some((attendee) => attendee.id === id)),
      ...newRoles,
    ]);
    setShowAttendeeModal(null);
  }, []);

  const onEditPages = useCallback(async (newRoles: PageRole[]) => {
    setUpdatedPageRoles((prev) => [
      ...(prev ?? []).filter(({ id }) => !newRoles.some((page) => page.id === id)),
      ...newRoles,
    ]);
    setShowPageModal(null);
  }, []);

  const onEditSessions = useCallback(async (newRoles: SessionRole[]) => {
    setUpdatedSessionRoles((prev) => [
      ...(prev ?? []).filter(({ id }) => !newRoles.some((session) => session.id === id)),
      ...newRoles,
    ]);
    setShowSessionModal(null);
  }, []);

  const onEditBreakouts = useCallback(async (newRoles: BreakoutRole[]) => {
    setUpdatedBreakoutRoles((prev) => [
      ...(prev ?? []).filter(({ id }) => !newRoles.some((session) => session.id === id)),
      ...newRoles,
    ]);
    setShowBreakoutModal(null);
  }, []);

  const onEditPermissionsRole = useCallback(
    async (data: {
      permissions: UserRolePermission[];
      visible: boolean;
      attendeesInSidebar: boolean;
    }) => {
      const { permissions, visible, attendeesInSidebar } = data;
      setUpdatedRoles((prev) =>
        prev.map((role) =>
          role.id === rolePermissionsModal
            ? { ...role, permissions, visible, attendeesInSidebar }
            : role,
        ),
      );
      setRolePermissionsModal(null);
    },
    [rolePermissionsModal],
  );

  const onDefaultRole = useCallback(async (id: string) => {
    setUpdatedRoles((prev) =>
      prev.map((role) => ({
        ...role,
        default: role.id === id,
      })),
    );
  }, []);

  const onDuplicateRole = useCallback(async (id: string) => {
    setUpdatedRoles((prev) => {
      const role = prev.find((rol) => rol.id === id)!;
      return [
        ...prev,
        {
          ...role,
          id: `new-${nanoid()}`,
          name: `${role.name} Copy`,
          default: false,
        },
      ];
    });
  }, []);

  const defaultRole = useMemo(() => roles?.find((role) => role.default), [roles]);

  const onDeleteRole = useCallback(
    async (roleId: string) => {
      const attendeeRole = updatedAttendeeRoles?.filter((role) => role.roleId === roleId) ?? [];
      const pageRole = updatedPageRoles?.filter((role) => role.roleIds.includes(roleId)) ?? [];
      const sessionRole =
        updatedSessionRoles?.filter((role) => role.roleIds.includes(roleId)) ?? [];
      const breakoutRole =
        updatedBreakoutRoles?.filter((role) => role.roleIds.includes(roleId)) ?? [];

      if (
        attendeeRole.length !== 0 ||
        pageRole.some((page) => page.roleIds.length > 1) ||
        sessionRole.some((page) => page.roleIds.length > 1) ||
        breakoutRole.some((page) => page.roleIds.length > 1)
      ) {
        message.error({
          content: (
            <div className='message-box-content-container'>
              <div className='message-box-content'>
                <h3>Delete Failed!</h3>
                <span style={{ textAlign: 'left' }}>
                  The role is in use by one or more items, please change their role.
                  <br />
                  Items with just this role assigned will be re-assigned to the default role
                  {defaultRole ? ` "${defaultRole.name}"` : ''}.
                </span>
              </div>
            </div>
          ),
          className: 'message-box',
          duration: 5,
        });
        return;
      }

      setUpdatedRoles((prevRoles) => {
        const newUpdatedRoles = prevRoles.filter((role) => role.id !== roleId);

        const singlePageRoles = pageRole.filter(
          (page) => page.roleIds.length === 1 && page.roleIds[0] === roleId,
        );
        setUpdatedPageRoles((prev) => {
          const updated = [...(prev ?? [])];
          singlePageRoles.forEach((page) => {
            const index = updated.findIndex(({ id }) => id === page.id);
            if (index)
              updated[index].roleIds = [
                newUpdatedRoles.find((role) => role.default)?.id ?? newUpdatedRoles[0].id,
              ];
          });
          return updated;
        });

        const singleSessionRoles = sessionRole.filter(
          (session) => session.roleIds.length === 1 && session.roleIds[0] === roleId,
        );
        setUpdatedSessionRoles((prev) => {
          const updated = [...(prev ?? [])];
          singleSessionRoles.forEach((session) => {
            const index = updated.findIndex(({ id }) => id === session.id);
            if (index)
              updated[index].roleIds = [
                newUpdatedRoles.find((role) => role.default)?.id ?? newUpdatedRoles[0].id,
              ];
          });
          return updated;
        });

        const singleBreakoutRoles = breakoutRole.filter(
          (breakout) => breakout.roleIds.length === 1 && breakout.roleIds[0] === roleId,
        );
        setUpdatedBreakoutRoles((prev) => {
          const updated = [...(prev ?? [])];
          singleBreakoutRoles.forEach((breakout) => {
            const index = updated.findIndex(({ id }) => id === breakout.id);
            if (index)
              updated[index].roleIds = [
                newUpdatedRoles.find((role) => role.default)?.id ?? newUpdatedRoles[0].id,
              ];
          });
          return updated;
        });

        return newUpdatedRoles;
      });
    },
    [
      defaultRole,
      updatedAttendeeRoles,
      updatedBreakoutRoles,
      updatedPageRoles,
      updatedSessionRoles,
    ],
  );

  const onAddRole = useCallback(() => {
    setUpdatedRoles((prev) => [
      ...prev,
      {
        id: `new-${nanoid()}`,
        name: 'Role',
        color: '#999',
        visible: true,
        attendeesInSidebar: true,
        default: false,
        userCount: 0,
        pageCount: 0,
        sessionCount: 0,
        breakoutCount: 0,
        permissions: [],
      },
    ]);
  }, []);

  const mainDistance = document.querySelector('main.site-layout-content')?.getBoundingClientRect();
  const nodeDistance = isComponentVisible.node?.getBoundingClientRect();
  const showPickerOnTop = (nodeDistance?.top ?? 0) + (265 + 15) > window.innerHeight;

  const allSingleAssignments = useMemo(
    () => [
      ...(updatedPageRoles
        ?.filter((page) => page.roleIds.length === 1)
        .map((page) =>
          page.roleIds.map((id) => ({ id, type: 'page', title: page.title ?? page.id })),
        )
        .flat() ?? []),
      ...(updatedSessionRoles
        ?.filter((session) => session.roleIds.length === 1)
        .map((session) =>
          session.roleIds.map((id) => ({
            id,
            type: 'session',
            title: session.title ?? session.id,
          })),
        )
        .flat() ?? []),
      ...(updatedBreakoutRoles
        ?.filter((breakout) => breakout.roleIds.length === 1)
        .map((breakout) =>
          breakout.roleIds.map((id) => ({
            id,
            type: 'breakout',
            title: breakout.title ?? breakout.id,
          })),
        )
        .flat() ?? []),
    ],
    [updatedPageRoles, updatedSessionRoles, updatedBreakoutRoles],
  );

  const beforeDeleteRole = useCallback(
    (roleId: string) => {
      if (allSingleAssignments.filter((role) => role.id === roleId).length > 0) {
        setShowDeleteModal(roleId);
      } else {
        onDeleteRole(roleId);
      }
    },
    [allSingleAssignments, onDeleteRole],
  );

  return (
    <EventLayout title='User Roles' extra={<EventLanguageSelect />}>
      <p style={{ fontWeight: 'bold', marginBottom: '20px' }}>
        Create predefined roles and assign to attendees to present customized views and features for
        different target groups.
        <br />
        Permissions within each role define the access and actions that an attendee has within the
        platform.
        <br />
        <br />
        Note: Each attendee can only have one User Role assignment.
        <br />
        Tip: In addition to customized platform content and features for each target group, User
        Role tags (labels) can be used as search filters.
      </p>
      <Alert
        showIcon
        type={visibilityDisabled ? 'error' : 'info'}
        style={{ marginBottom: 10 }}
        message='User Role Tags'
        description={
          <>
            For each individual user role, you can enable the visibility of the tag (label) via
            permission settings.
            <br />
            {visibilityDisabled ? (
              <>
                Right now, all tags (labels) have been hidden in the{' '}
                <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link>.
              </>
            ) : (
              <>
                You can toggle this feature for all tags (labels) in the{' '}
                <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link>.
              </>
            )}
          </>
        }
      />
      <Alert
        showIcon
        type={attendeesSidebarDisabled ? 'error' : 'info'}
        message='Attendee Shortcut Icon'
        description={
          <>
            For each individual user role, you can enable the visibility of the Attendee Shortcut
            Icon (in sidebar) via permission settings.
            <br />
            {attendeesSidebarDisabled ? (
              <>
                Right now, this feature is disabled for all User Roles in the{' '}
                <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link>.
                <br />
                <i>
                  Please note that the endpoint for retrieving all attendees will always be
                  available, regardless of this setting.
                </i>
              </>
            ) : (
              <>
                You can toggle this feature for all User Roles in the{' '}
                <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link>.
              </>
            )}
          </>
        }
      />
      <div ref={columns} />
      <Table
        loading={isLoading || isFetching}
        style={{ margin: '20px 0' }}
        dataSource={updatedRoles}
        pagination={false}
        columns={[
          {
            title: 'Default',
            key: 'default',
            dataIndex: 'default',
            width: 60,
            sorter: (a) => (a.default ? 1 : -1),
            render: (enabled, { id }) => {
              const render = (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Radio
                    key='default'
                    name='default'
                    checked={enabled}
                    onClick={() => onDefaultRole(id)}
                    style={{ marginLeft: '5px' }}
                  />
                </div>
              );
              return showHeaderTooltip ? (
                <Tooltip title='Default' key={`default-${id}`}>
                  {render}
                </Tooltip>
              ) : (
                render
              );
            },
          },
          {
            title: 'Color',
            key: 'color',
            dataIndex: 'color',
            width: 60,
            sorter: (a, b) => a.color.localeCompare(b.color),
            render: (color, { id }) => {
              const render = (
                <div
                  onClick={(e) => setIsComponentVisible({ id, node: e.target as HTMLDivElement })}
                  style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      cursor: 'pointer',
                      backgroundColor: color ?? '#999',
                      width: '24px',
                      height: '24px',
                      borderRadius: '12px',
                    }}
                  />
                </div>
              );
              return showHeaderTooltip ? (
                <Tooltip title='Color' key={`color-${id}`}>
                  {render}
                </Tooltip>
              ) : (
                render
              );
            },
          },
          {
            title: 'Name (Tag)',
            key: 'name',
            dataIndex: 'name',
            width: 200,
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (name, { id }) => (
              <Input
                key='name'
                value={name}
                onChange={({ target }) =>
                  setUpdatedRoles((prev) =>
                    prev.map((role) => (role.id !== id ? role : { ...role, name: target.value })),
                  )
                }
              />
            ),
          },
          {
            title: 'Permissions',
            key: 'permissions',
            dataIndex: 'permissions',
            width: 200,
            sorter: (a, b) => {
              const aPerms = [
                ...a.permissions,
                a.visible ? 'VISIBLE' : false,
                a.attendeesInSidebar ? 'ATTENDEES_IN_SIDEBAR' : false,
              ].filter(Boolean);
              const bPerms = [
                ...b.permissions,
                b.visible ? 'VISIBLE' : false,
                b.attendeesInSidebar ? 'ATTENDEES_IN_SIDEBAR' : false,
              ].filter(Boolean);
              return aPerms.length - bPerms.length;
            },
            render: (permissions: UserRolePermission[], { visible, attendeesInSidebar }) => (
              <ul style={{ margin: 0 }}>
                {visible && <li key='visible'>Name (Tag)</li>}
                {permissions.includes(UserRolePermission.HIDE_USERS) && (
                  <li key='hideUsers'>Hidden Attendees</li>
                )}
                {attendeesInSidebar && <li key='attendeesInSidebar'>Attendee Shortcut Icon</li>}
                {permissions.includes(UserRolePermission.HOST_CHAT) && (
                  <li key='hostChat'>Virtual Event Host</li>
                )}
                {permissions.includes(UserRolePermission.VIDEO_CALL_HOST) && (
                  <li key='videoCallHost'>Video Call Host</li>
                )}
                {permissions.includes(UserRolePermission.SEND_GROUP_CHAT) && (
                  <li key='groupChat'>Group Chat</li>
                )}
                {permissions.includes(UserRolePermission.CREATE_PRIVATE_CHAT) && (
                  <li key='privateChat'>Private Chat</li>
                )}
                {permissions.includes(UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS) && (
                  <li key='privateVideoChat'>Private Video Chat</li>
                )}
              </ul>
            ),
          },
          {
            title: 'Attendees',
            key: 'userCount',
            dataIndex: 'userCount',
            width: 80,
            sorter: (a, b) => (a.userCount ?? 0) - (b.userCount ?? 0),
            render: (_, role) => {
              const count =
                updatedAttendeeRoles?.filter(({ roleId }) => roleId === role.id).length ?? 0;
              const render = (
                <Tag
                  color={count > 0 ? 'blue' : undefined}
                  style={{ cursor: 'pointer', minWidth: 50, textAlign: 'center' }}
                  onClick={() => setShowAttendeeModal(role)}
                >
                  {count ?? 0}
                </Tag>
              );
              return showHeaderTooltip ? (
                <Tooltip title='Attendees' key={`userCount-${role.id}`}>
                  {render}
                </Tooltip>
              ) : (
                render
              );
            },
          },
          {
            title: 'Pages',
            key: 'pageCount',
            dataIndex: 'pageCount',
            width: 80,
            sorter: (a, b) => (a.pageCount ?? 0) - (b.pageCount ?? 0),
            render: (_, role) => {
              const count =
                (updatedPageRoles?.filter(({ roleIds }) => roleIds.includes(role.id)).length ?? 0) +
                (updatedPageRoles?.filter(({ roleIds }) => roleIds.length === 0).length ?? 0);
              const render = (
                <Tag
                  color={count > 0 ? 'blue' : undefined}
                  style={{ cursor: 'pointer', minWidth: 50, textAlign: 'center' }}
                  onClick={() => setShowPageModal(role)}
                >
                  {count ?? 0}
                </Tag>
              );
              return showHeaderTooltip ? (
                <Tooltip title='Pages' key={`pageCount-${role.id}`}>
                  {render}
                </Tooltip>
              ) : (
                render
              );
            },
          },
          {
            title: 'Sessions',
            key: 'sessionCount',
            dataIndex: 'sessionCount',
            width: 80,
            sorter: (a, b) => (a.sessionCount ?? 0) - (b.sessionCount ?? 0),
            render: (_, role) => {
              const count =
                (updatedSessionRoles?.filter(({ roleIds }) => roleIds.includes(role.id)).length ??
                  0) +
                (updatedSessionRoles?.filter(({ roleIds }) => roleIds.length === 0).length ?? 0);
              const render = (
                <Tag
                  color={count > 0 ? 'blue' : undefined}
                  style={{ cursor: 'pointer', minWidth: 50, textAlign: 'center' }}
                  onClick={() => setShowSessionModal(role)}
                >
                  {count ?? 0}
                </Tag>
              );
              return showHeaderTooltip ? (
                <Tooltip title='Sessions' key={`sessionCount-${role.id}`}>
                  {render}
                </Tooltip>
              ) : (
                render
              );
            },
          },
          {
            title: 'Breakouts',
            key: 'breakoutCount',
            dataIndex: 'breakoutCount',
            width: 80,
            sorter: (a, b) => (a.breakoutCount ?? 0) - (b.breakoutCount ?? 0),
            render: (_, role) => {
              const count =
                (updatedBreakoutRoles?.filter(({ roleIds }) => roleIds.includes(role.id)).length ??
                  0) +
                (updatedBreakoutRoles?.filter(({ roleIds }) => roleIds.length === 0).length ?? 0);
              const render = (
                <Tag
                  color={count > 0 ? 'blue' : undefined}
                  style={{ cursor: 'pointer', minWidth: 50, textAlign: 'center' }}
                  onClick={() => setShowBreakoutModal(role)}
                >
                  {count ?? 0}
                </Tag>
              );
              return showHeaderTooltip ? (
                <Tooltip title='Breakouts' key={`breakoutCount-${role.id}`}>
                  {render}
                </Tooltip>
              ) : (
                render
              );
            },
          },
          {
            title: 'Actions',
            key: 'actions',
            dataIndex: 'actions',
            align: 'right',
            width: 145,
            render: (_, { id, default: isDefault }) => [
              <Tooltip title='Duplicate' key={`duplicate-${id}`}>
                <Button
                  shape='circle'
                  icon={<CopyOutlined />}
                  onClick={() => onDuplicateRole(id)}
                />
              </Tooltip>,
              <Divider key='d1' type='vertical' />,
              <Tooltip title='Edit Permissions' key={`edit-${id}`}>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => setRolePermissionsModal(id)}
                />
              </Tooltip>,
              <Divider key='d2' type='vertical' />,
              <Tooltip
                title={
                  isDefault ? 'This role is default and therefore cannot be deleted' : 'Delete'
                }
                key={`delete-${id}`}
              >
                <Popconfirm
                  title='Are you sure you want to delete this role?'
                  onConfirm={() => beforeDeleteRole(id)}
                  okText='Delete'
                  cancelText='Cancel'
                  disabled={isDefault === true}
                >
                  <Button
                    shape='circle'
                    icon={<DeleteOutlined />}
                    danger
                    disabled={isDefault === true}
                  />
                </Popconfirm>
              </Tooltip>,
            ],
          },
        ]}
      />
      <div ref={colorPicker}>
        {!!isComponentVisible.id && (
          <div
            className={`role-color-picker-wrapper${showPickerOnTop ? ' top' : ''}`}
            style={{
              position: 'absolute',
              top:
                (nodeDistance?.top ?? 0) -
                (mainDistance?.top ?? 0) +
                (showPickerOnTop ? -265 - 15 : (nodeDistance?.height ?? 0) + 15),
              left:
                (nodeDistance?.left ?? 0) -
                (mainDistance?.left ?? 0) +
                (nodeDistance?.width ?? 0) / 2 -
                255 / 2,
              width: '255px',
              height: '265px',
              padding: '15px',
              backgroundColor: 'white',
              border: '1px solid #ebebeb',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
              borderRadius: '4px',
              zIndex: 100000,
              transform: 'translateZ(100000px)',
            }}
          >
            <ChromePicker
              className='role-color-picker'
              color={
                updatedRoles.find((role) => role.id === isComponentVisible.id)?.color ?? '#999'
              }
              onChangeComplete={(newColor) =>
                setUpdatedRoles((prev) =>
                  prev.map((role) =>
                    role.id !== isComponentVisible.id ? role : { ...role, color: newColor.hex },
                  ),
                )
              }
            />
          </div>
        )}
      </div>
      <Button onClick={onAddRole} style={{ float: 'left' }}>
        Add Role
      </Button>
      <Button type='primary' onClick={onUpdateRoles} style={{ float: 'right' }}>
        Save Changes
      </Button>
      <LazyModal open={!!rolePermissionsModal}>
        {(open) => (
          <EditRolePermissionsModal
            visible={open}
            onOk={onEditPermissionsRole}
            onCancel={() => setRolePermissionsModal(null)}
            event={event}
            data={{
              visible:
                updatedRoles.find((role) => role.id === rolePermissionsModal)?.visible || false,
              attendeesInSidebar:
                updatedRoles.find((role) => role.id === rolePermissionsModal)?.attendeesInSidebar ||
                false,
              initalPermissions:
                updatedRoles.find((role) => role.id === rolePermissionsModal)?.permissions ?? [],
            }}
          />
        )}
      </LazyModal>
      <LazyModal open={!!showAttendeeModal}>
        {(open) => (
          <UserModal
            visible={open}
            onOk={onEditUsers}
            onCancel={() => setShowAttendeeModal(null)}
            roles={updatedRoles ?? []}
            roleId={showAttendeeModal?.id!}
            allRoles={updatedAttendeeRoles}
            attendeeRoles={updatedAttendeeRoles?.filter(
              (attendee) => attendee.roleId === showAttendeeModal?.id,
            )}
          />
        )}
      </LazyModal>
      <LazyModal open={!!showPageModal}>
        {(open) => (
          <PageModal
            visible={open}
            onOk={onEditPages}
            onCancel={() => setShowPageModal(null)}
            roles={updatedRoles ?? []}
            roleId={showPageModal?.id!}
            pageRoles={updatedPageRoles}
            defaultRole={defaultRole}
          />
        )}
      </LazyModal>
      <LazyModal open={!!showSessionModal}>
        {(open) => (
          <SessionModal
            visible={open}
            onOk={onEditSessions}
            onCancel={() => setShowSessionModal(null)}
            roles={updatedRoles ?? []}
            roleId={showSessionModal?.id!}
            sessionRoles={updatedSessionRoles}
            defaultRole={defaultRole}
          />
        )}
      </LazyModal>
      <LazyModal open={!!showBreakoutModal}>
        {(open) => (
          <BreakoutModal
            visible={open}
            onOk={onEditBreakouts}
            onCancel={() => setShowBreakoutModal(null)}
            roles={updatedRoles ?? []}
            roleId={showBreakoutModal?.id!}
            breakoutRoles={updatedBreakoutRoles}
            defaultRole={defaultRole}
          />
        )}
      </LazyModal>
      <LazyModal open={!!showDeleteModal}>
        {(open) => (
          <DeleteWarningModal
            visible={open}
            onOk={() => {
              onDeleteRole(showDeleteModal!);
              setShowDeleteModal(null);
            }}
            onCancel={() => setShowDeleteModal(null)}
            defaultRoleName={defaultRole?.name}
            singleAssignments={allSingleAssignments.filter((role) => role.id === showDeleteModal)}
          />
        )}
      </LazyModal>
    </EventLayout>
  );
}
